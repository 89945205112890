(() => {	
	const playIconContainer = document.querySelectorAll('.play-icon');
	const audioPlayerContainer = document.querySelectorAll('.audio-player-container');
	const seekSlider = document.querySelectorAll('.seek-slider');
	const volumeSlider = document.querySelectorAll('.volume-slider');
	const muteIconContainer = document.querySelectorAll('.mute-icon');
	let playState = 'play';
	let muteState = 'unmute';

	let audioPlaying = 0;
	let volumeValue = 100;

	playIconContainer.forEach((playIcon, index) => {
	  playIcon.addEventListener('click', () => {
		if(playState === 'play') {
			audios[index].play();
			audioPlaying = index;
			playIcon.classList.add('paused');
			requestAnimationFrame(whilePlaying);
			playState = 'pause';
		} else {
		  if (index !== audioPlaying && !audios[audioPlaying].paused) {
			audios[audioPlaying].pause();
			playIconContainer[audioPlaying].classList.remove('paused');
			audioPlaying = index;
			audios[index].play();
			playIcon.classList.add('paused');
		  } else {
			audios[index].pause();
			playIcon.classList.remove('paused');
			cancelAnimationFrame(raf);
			playState = 'play';
		  }
		}
	  });
	});

	muteIconContainer.forEach(muteIcon => {
	  muteIcon.addEventListener('click', () => {
		if(muteState === 'unmute') {
		    muteIcon.classList.add('muted');
			audios[audioPlaying].muted = true;
			volumeValue = audios[audioPlaying].volume * 100;
			volumeSlider.value = 0;
			muteState = 'mute';
		} else { 
		    muteIcon.classList.remove('muted');
			audios[audioPlaying].muted = false;
			muteState = 'unmute';
			volumeSlider.value = volumeValue;
		}
	})
	});

	const showRangeProgress = (rangeInput, index) => {
		if(rangeInput === seekSlider[index]) audioPlayerContainer[index].style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%');
		else audioPlayerContainer[index].style.setProperty('--volume-before-width', rangeInput.value / rangeInput.max * 100 + '%');
	}

	seekSlider.forEach((seekSlide, index) => {
	  audioPlaying = index;
	  seekSlide.addEventListener('input', (event) => {
		showRangeProgress(event.target, index);
	  })
	})

	volumeSlider.forEach((voluemSlide, index) => {
	  voluemSlide.addEventListener('input', (e) => {
		if (Number(e.target.value) === 0) {
		  muteIconContainer[index].classList.add('muted')
		  muteState = 'mute';
		} else {
		  muteIconContainer[index].classList.remove('muted')
		  muteState = 'unmute';
		}
		showRangeProgress(e.target, index);
	  })
	});


	/** Implementation of the functionality of the audio player */

	const audios = document.querySelectorAll('audio');
	const durationContainer = document.querySelectorAll('.duration');
	const currentTimeContainer = document.querySelectorAll('.current');
	let raf = null;

	const calculateTime = (secs) => {
		const minutes = Math.floor(secs / 60);
		const seconds = Math.floor(secs % 60);
		const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
		return `${minutes}:${returnedSeconds}`;
	}

	const displayDuration = () => {
	  durationContainer.forEach((duration, index) => {
		duration.textContent = calculateTime(audios[index].duration)
	  })
	}

	const setSliderMax = () => {
	  seekSlider.forEach((seek, index) => {
		seek.max = Math.floor(audios[index].duration)
	  })
	}

	const displayBufferedAmount = (song, index) => {
		const bufferedAmount = Math.floor(song.buffered.end(song.buffered.length - 1));
		audioPlayerContainer[index].style.setProperty('--buffered-width', `${(bufferedAmount / seekSlider[index].max) * 100}%`);
	}

	const whilePlaying = () => {
	  seekSlider[audioPlaying].value = Math.floor(audios[audioPlaying].currentTime);
	  currentTimeContainer[audioPlaying].textContent = calculateTime(seekSlider[audioPlaying].value);
	  audioPlayerContainer[audioPlaying].style.setProperty('--seek-before-width', `${seekSlider[audioPlaying].value / seekSlider[audioPlaying].max * 100}%`);
	  raf = requestAnimationFrame(whilePlaying);
	}
	
  /* no timeout, to prevent script running before audios are loaded */

	audios.forEach((song, index) => {
		if (song.readyState > 0) {
			displayDuration();
			setSliderMax();
			// displayBufferedAmount(song, index);
		} else {
			song.addEventListener('loadedmetadata', () => {
				displayDuration();
				setSliderMax();
				// displayBufferedAmount(song, index);
			});
		}

		song.addEventListener('progress', () => {
			// displayBufferedAmount(song, index);
		})
	})
	
	seekSlider.forEach((seekSlide, index) => {
	  seekSlide.addEventListener('input', () => {
		currentTimeContainer.textContent = calculateTime(seekSlide.value);
		if(!audios[index].paused) {
			cancelAnimationFrame(raf);
		}
	  })
	});

	seekSlider.forEach((seekSlide, index) => {
	  seekSlide.addEventListener('change', () => {
		audioPlaying = index;
		audios[index].currentTime = seekSlide.value;
		if(!audios[index].paused) {
			requestAnimationFrame(whilePlaying);
		}
	  })
	});

	volumeSlider.forEach((voluemSlide, index) => {
	  voluemSlide.addEventListener('input', (e) => {
		const value = e.target.value;

		audios[index].volume = value / 100;
	  })
	});
	
	/* numbering of audios */
	
	const audioBlocks = document.querySelectorAll('.highlighted-block.narrow-block');

	audioBlocks.forEach((container, index) => {
		if (index !== 0) {
			const audioNumber = document.createElement('div');
			audioNumber.classList.add('audio-numbering');
			audioNumber.textContent = index;
			container.appendChild(audioNumber);			
		}
	});
})();